export const environment = {
  production: false,
  config: {
    serverUrl: 'https://data-explorer.zaidyn.dev.zsservices.com',
    loginUrl:
      'https://idm.dev.zsservices.com/RWB/IdentityManager/app/Web/login.aspx?ReturnUrl=',
    logoutUrl:
      'https://idm.dev.zsservices.com/RWB/IdentityManager/app/Web/logout.aspx',
    idmAccountUrl:
      'https://idm.dev.zsservices.com/RWB/IdentityManager/app/Web/MyAccount.aspx',
    pacHomeUrl: 'https://pac-home.zaidyn.dev.zsservices.com',
    gtagId: 'G-DXWCZEJ6ZF',
    region: 'JP',
    client: 'Dev',
  },
  authEnabled: false,
  authConfig: {
    issuer: 'https://federationdev.abbvie.com',
    redirectUri: 'https://data-explorer.zaidyn.dev.zsservices.com',
    clientId: 'mfa.cdldev',
    responseType: 'code',
    cookieDomain: 'zsservices.com',
  },
};
