import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppState {
  public static appState = new BehaviorSubject(null);

  constructor() {
    let gbState: any = window.sessionStorage.getItem('globalState');
    if (gbState === null || gbState === undefined) {
      gbState = JSON.parse('{}');
    } else {
      if (typeof gbState === 'string') {
        try {
          gbState = JSON.parse(gbState);
        } catch (e) {
          gbState = JSON.parse('{}');
        }
      }
    }
    AppState.appState.next(gbState);
  }

  // Exposed Functions --------------------------------------------------------------------- //
  public setGlobalState(key: any, value: any) {
    // Check Global State Definition
    let temp: any = AppState.appState.getValue();

    if (temp === null || temp === undefined) {
      temp = {};
    } else {
      if (typeof temp === 'string') {
        try {
          temp = JSON.parse(temp);
        } catch (e) {
          temp = {};
        }
      } else {
        try {
          temp = JSON.stringify(temp);
          temp = JSON.parse(temp);
        } catch (e) {
          temp = {};
        }
      }
    }

    // Set Global State Value
    try {
      temp[key] = value;
    } catch (e) {
      temp = {};
      temp[key] = value;
    }

    sessionStorage.setItem('globalState', JSON.stringify(temp));
    AppState.appState.next(temp);
  }

  public removeGlobalState(key: string) {
    let temp: any = AppState.appState.getValue();
    if (temp === null || temp === undefined) {
      temp = {};
    } else {
      if (typeof temp === 'string') {
        try {
          temp = JSON.parse(temp);
        } catch (e) {
          temp = {};
        }
      } else {
        try {
          temp = JSON.stringify(temp);
          temp = JSON.parse(temp);
        } catch (e) {
          temp = {};
        }
      }
    }
    delete temp[key];

    AppState.appState.next(temp);
    window.sessionStorage.setItem('globalState', JSON.stringify(temp));
  }

  public clearGlobalState() {
    AppState.appState.next(null);
    window.sessionStorage.clear();
  }
}
